import React, { useEffect } from "react";

import { Stack, Container, Grid } from "@mui/material";

import { SectionHeader } from "../../components";
import { imageMap } from "../../../../.images";

import "./styles.css";
import { TalkToUsProps } from "./types";

export const TalkToUs = ({ talkTitle }: TalkToUsProps) => {
  useEffect(() => {
    setTimeout(() => {
      window &&
        (window as any)?.MktoForms2?.loadForm(
          "//resources.trendmicro.com",
          "945-CXD-062",
          28777
        );
    });
  }, []);

  return (
    <>
      <div id="contact">
        <Container
          disableGutters
          maxWidth={false}
          sx={{
            background: `url(${imageMap["form-bg"]()})`,
          }}
        >
          <Container maxWidth="lg">
            <Stack spacing={2} py="64px" alignItems="center">
              <SectionHeader title={talkTitle} />
              <Container maxWidth="md">
                <Grid
                  container
                  spacing={2}
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Grid item xs={12}>
                    <form id="mktoForm_28777" />
                  </Grid>
                </Grid>
              </Container>
            </Stack>
          </Container>
        </Container>
      </div>
    </>
  );
};
