import React from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";

import { Routes } from "../../routes";

import { Header } from "./header";
import { Footer } from "./footer";
import { Search } from "./search";
import { Backdrop, Box } from "@mui/material";

const App = (props: any) => {
  const [showSearch, setShowSearch] = React.useState(false);
  return (
    <>
      <HelmetProvider>
        <div>
          <Helmet>
            <title>Trends Campus UI</title>
            <meta name="Trends Campus UI" content="Trends Campus UI" />
          </Helmet>
        </div>
      </HelmetProvider>
      <Header
        showSearch={showSearch}
        onClickSearch={() => {
          setShowSearch((prev) => !prev);
        }}
      />
      <Box
        component="main"
        sx={{ pt: 9, background: "#000", boxShadow: "none" }}
      >
        <Routes {...props} />
      </Box>
      <Backdrop
        sx={{
          color: "#fff",
          zIndex: (theme) => theme.zIndex.drawer + 1,
          backgroundColor: "rgba(0, 0, 0, 0.9)",
        }}
        open={showSearch}
      >
        <Search
          onClickSearch={() => {
            setShowSearch((prev) => !prev);
          }}
          showSearch={showSearch}
        />
      </Backdrop>
      <Footer />
    </>
  );
};
export default App;
