import {
  Facebook,
  Instagram,
  LinkedIn,
  YouTube,
  Twitter,
  FacebookRounded,
} from "@mui/icons-material";

export const socialMediaicons = [
  {
    Icon: LinkedIn,
    StickyIcon: LinkedIn,
    url: "https://www.linkedin.com/company/trend-micro-amea/?originalSubdomain=sg",
  },
  {
    Icon: Facebook,
    StickyIcon: FacebookRounded,
    url: "https://www.facebook.com/TrendMicroAMEA/",
  },
  {
    Icon: Twitter,
    StickyIcon: Twitter,
    url: "https://twitter.com/trendmicroamea?lang=en",
  },
  {
    Icon: Instagram,
    StickyIcon: Instagram,
    url: "https://www.instagram.com/trendmicro/?hl=en",
  },
  {
    Icon: YouTube,
    StickyIcon: YouTube,
    url: "https://www.youtube.com/c/TrendMicroAMEA",
  },
];
