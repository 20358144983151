import * as React from "react";

import { useQuery } from "@apollo/client";
import YouTube from "react-youtube";
import { useParams } from "react-router-dom";

import { VIDEO_PAGE } from "../../api";
import {
  Backdrop,
  Box,
  CircularProgress,
  Container,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";

import { RelatedVideos } from "./videos";
import { imageMap } from "../../.images";

export const DefinitionList = ({ label, value }: any) => {
  return (
    <Stack direction="row">
      <Typography
        variant="h6"
        fontWeight={300}
        pr="4px"
        fontSize={16}
        sx={(theme) => ({
          color: theme.palette.secondary.light,
        })}
      >
        {`${label}: `}
      </Typography>
      <Typography
        variant="h6"
        fontSize={16}
        sx={(theme) => ({
          color: theme.palette.secondary.light,
        })}
      >
        {value}
      </Typography>
    </Stack>
  );
};

const VideoPage = () => {
  const { videoId } = useParams<{ videoId: string }>();

  const [showVideo, setShowVideo] = React.useState(false);

  const { loading, data } = useQuery(VIDEO_PAGE, {
    variables: {
      ...(videoId && { id: videoId }),
    },
  });

  const responseData = data?.webinar?.acfPortfolio || {};

  React.useEffect(() => {
    setShowVideo(false);
  }, [videoId]);
  const matches = useMediaQuery("(max-width:764px)");
  return (
    <>
      {loading ? (
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={loading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      ) : (
        <Box pt="20px">
          {showVideo ? (
            <Box sx={{ height: matches ? "80vh" : "calc(100vh)" }}>
              <YouTube
                videoId={responseData.youtubeVideoId || ""}
                opts={{
                  width: "100%",
                  height: "100%",
                  playerVars: {
                    autoplay: 1,
                    modestbranding: 1,
                    rel: 0,
                    loop: 1,
                    autoHide: 1,
                    showInfo: 0,
                    controls: 1,
                    iv_load_policy: 3,
                  },
                }}
                style={{ height: "100%" }}
              />
            </Box>
          ) : (
            <Box
              sx={{
                height: matches ? "80vh" : "calc(100vh)",
                "&:before": {
                  content: '""',
                  position: "absolute",
                  top: 0,
                  width: "100%",
                  height: "100%",
                  zIndex: -1,
                  bottom: 0,
                  backgroundImage: `url(${imageMap[
                    "Red-Black-Gradient-3-with-pattern"
                  ]()})`,
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "cover",
                  backgroundPosition: "bottom right",
                  transform: "rotateY(180deg)",
                },
                py: 6,
                position: "relative",
                zIndex: 1,
              }}
            >
              <Container
                disableGutters
                maxWidth={false}
                sx={{ height: "100%" }}
              >
                <Container maxWidth="lg" sx={{ height: "100%" }}>
                  <Stack
                    direction={matches ? "column" : "row"}
                    sx={{
                      height: "calc(100% - 70px)",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <Stack maxWidth={matches ? "100%" : "500px"}>
                      <Typography
                        variant="h3"
                        sx={(theme) => ({
                          color: theme.palette.secondary.light,
                        })}
                        fontWeight={600}
                        pb={3}
                      >
                        {data?.webinar?.title}
                      </Typography>
                      <DefinitionList
                        label="Author"
                        value={responseData.speakerName}
                      />
                      <DefinitionList
                        label="Flavour"
                        value={(data?.webinar?.flavours?.nodes || [])
                          .map((it: any) => it.name)
                          .join(" ")}
                      />
                      <DefinitionList
                        label="Intensity"
                        value={(data?.webinar?.intensities?.nodes || [])
                          .map((it: any) => it.name)
                          .join(" ")}
                      />
                    </Stack>
                    <img
                      src={`${process.env.CMS_URI}/wp-content/uploads/2023/06/index-media-cover-art-play-button-overlay-5-fotor-bg-remover-2023061310436.png`}
                      alt="thumbnail"
                      style={{
                        width: matches ? "150px" : "250px",
                        height: matches ? "150px" : "250px",
                        marginRight: matches ? "0" : "30px",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        setShowVideo(true);
                      }}
                    />
                  </Stack>
                </Container>
              </Container>
            </Box>
          )}

          <RelatedVideos
            title="Related Videos"
            videos={responseData.relatedVideos || []}
          />
        </Box>
      )}
    </>
  );
};

export default VideoPage;
