import {
  Container,
  Box,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  Divider,
  Stack,
  ListItemText,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';

import { socialMediaicons } from '../../config/icon';
import { legalItems } from '../../config/menu';

import { imageMap } from '../../.images';
import './styles.css';

export const Footer = () => {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.down('xl'));
  return (
    <Container
      disableGutters
      maxWidth={false}
      sx={{ background: '#000', pb: 4, pt: 7 }}
    >
      <Container maxWidth='lg' disableGutters={isDesktop ? false : true}>
        <Stack direction='row' justifyContent='space-between'>
          <img
            src={imageMap['TRENDsCampus-logo']()}
            width={100}
            height={50}
            alt='Trendcampus Logo'
          />
          <List sx={{ display: 'flex' }}>
            {socialMediaicons.map((Icon, ind) => (
              <ListItem sx={{ px: 0, width: 'inherit' }} key={ind}>
                <ListItemButton
                  sx={{ pl: 0, pr: '12px' }}
                  href={Icon.url}
                  target='_blank'
                >
                  <ListItemIcon className='social-icons' sx={{ px: 0 }}>
                    <Icon.Icon htmlColor='#000' fontSize='small' />
                  </ListItemIcon>
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        </Stack>
        <Divider sx={{ borderColor: '#262626', pt: 2 }} />
        <Stack direction='row' justifyContent='space-between' pt={1}>
          <List sx={{ display: 'flex', py: 0 }}>
            {legalItems.map((item, ind) => (
              <ListItem
                key={ind}
                component='div'
                disablePadding
                sx={{
                  px: ind === 0 ? 0 : 1,
                  width: 'inherit',
                  position: 'relative',
                  '&:after': {
                    content: '""',
                    borderRight:
                      ind === 0 ? '1px solid rgb(183 183 183 / 99%)' : 'none',
                    position: 'absolute',
                    right: 0,
                    height: '50%',
                  },
                }}
              >
                <ListItemButton
                  sx={{ pl: 0, py: 0 }}
                  onClick={() => {
                    window.open(item.url, '_blank');
                  }}
                >
                  <ListItemText
                    primaryTypographyProps={{
                      fontWeight: 300,
                      fontSize: 'small',
                    }}
                    sx={{ color: 'white', my: 0 }}
                    primary={item.label}
                  />
                </ListItemButton>
              </ListItem>
            ))}
          </List>
          <Box
            alignItems='center'
            justifyContent=''
            display='flex'
            sx={{
              height: '100%',
              justifyContent: { sm: 'flex-end', xs: 'flex-start' },
            }}
          >
            <Typography
              color={(theme) => theme.palette.secondary.light}
              fontWeight={300}
              fontSize='small'
            >
              {`Copyright ©️ ${String(
                new Date().getFullYear()
              )} Trend Micro Incorporated. All rights reserved.`}
            </Typography>
          </Box>
        </Stack>
      </Container>
    </Container>
  );
};
