import * as React from "react";
import {
  Stepper,
  Step,
  StepLabel,
  StepContent,
  Box,
  Typography,
  StepIconProps,
  StepConnector,
  stepConnectorClasses,
  stepLabelClasses,
  stepContentClasses,
  StepContentProps,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";

import { styled } from "@mui/material/styles";

import { FaqProps } from "./types";

const StyledConnector = styled(StepConnector)(() => ({
  [`&.${stepConnectorClasses.root}`]: {
    marginLeft: "2.5rem",
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderLeftStyle: "dotted",
  },
}));

const StyledStepLabel = styled(StepLabel)(() => ({
  [`&.${stepLabelClasses.root}`]: {
    cursor: "pointer",
  },
  [`& .${stepLabelClasses.iconContainer}`]: {
    paddingRight: "1rem",
  },
}));

const StyledStepContent = styled(
  ({ showPad, ...rest }: { showPad: boolean } & StepContentProps) => (
    <StepContent {...rest} />
  )
)(({ showPad }) => {
  return {
    [`&.${stepContentClasses.root}`]: {
      marginLeft: "1rem",
      borderLeft: "1px dotted #bdbdbd",
      paddingLeft: "2rem",
      paddingBottom: showPad ? "1rem" : 0,
    },
  };
});

const ColorlibStepIconRoot = styled("div")<{}>(({ theme }) => ({
  zIndex: 1,
  display: "flex",
  borderRadius: "50%",
  justifyContent: "center",
  alignItems: "center",
  img: {
    width: "2rem",
  },
}));

function ColorlibStepIcon(props: StepIconProps) {
  const { active, className } = props;
  return (
    <ColorlibStepIconRoot className={className}>
      <Box
        sx={(theme) => ({
          height: "2rem",
          width: "2rem",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          borderRadius: "50%",
          backgroundColor: theme.palette.secondary.light,
          border: `1px solid ${
            active ? theme.palette.primary.main : theme.palette.secondary.light
          }`,
        })}
      >
        {active ? <RemoveIcon color="primary" /> : <AddIcon color="primary" />}
      </Box>
    </ColorlibStepIconRoot>
  );
}

export const FaqList = ({ faqs }: FaqProps) => {
  const [activeStep, setActiveStep] = React.useState(0);
  return (
    <Box>
      <Stepper
        activeStep={activeStep}
        orientation="vertical"
        nonLinear
        connector={<StyledConnector />}
      >
        {faqs.slice(0, 5).map((faq, index) => (
          <Step
            key={index}
            sx={(theme) => ({
              padding: "0 1.5rem",
              paddingTop: index === 0 ? "1rem" : 0,
              background:
                activeStep === index
                  ? theme.palette.secondary.light
                  : "inherit",
              borderRadius: "9px",
              paddingBottom: index === faqs.length - 1 ? "1rem" : 0,
            })}
          >
            <StyledStepLabel
              onClick={() => {
                setActiveStep(index);
              }}
              StepIconComponent={ColorlibStepIcon}
            >
              <Typography
                color={(theme) =>
                  activeStep === index
                    ? theme.palette.secondary.dark
                    : theme.palette.secondary.light
                }
                variant="h6"
                fontSize={activeStep === index ? 18 : 16}
              >
                {faq.questions}
              </Typography>
            </StyledStepLabel>
            <StyledStepContent
              showPad={activeStep === index && index !== faqs.length - 1}
            >
              <Typography
                color={(theme) => theme.palette.secondary.dark}
                fontWeight={300}
                component="div"
              >
                <div dangerouslySetInnerHTML={{ __html: faq.answer }} />
              </Typography>
            </StyledStepContent>
          </Step>
        ))}
      </Stepper>
    </Box>
  );
};
