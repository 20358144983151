import * as React from "react";
import {
  Stepper,
  Step,
  StepLabel,
  StepContent,
  Box,
  Typography,
  StepIconProps,
  StepConnector,
  stepConnectorClasses,
  stepLabelClasses,
  stepContentClasses,
  StepContentProps,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { ImageComponent } from "../../../../components";

import { FlavourProps } from "./types";

const StyledConnector = styled(StepConnector)(() => ({
  [`&.${stepConnectorClasses.root}`]: {
    marginLeft: "2.5rem",
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderLeftStyle: "dotted",
  },
}));

const StyledStepLabel = styled(StepLabel)(() => ({
  [`&.${stepLabelClasses.root}`]: {
    cursor: "pointer",
  },
  [`& .${stepLabelClasses.iconContainer}`]: {
    paddingRight: "1rem",
  },
}));

const StyledStepContent = styled(
  ({ showPad, ...rest }: { showPad: boolean } & StepContentProps) => (
    <StepContent {...rest} />
  )
)(({ showPad }) => {
  return {
    [`&.${stepContentClasses.root}`]: {
      marginLeft: "1rem",
      borderLeft: "1px dotted #bdbdbd",
      paddingLeft: "2rem",
      paddingBottom: showPad ? "1rem" : 0,
    },
  };
});

const ColorlibStepIconRoot = styled("div")<{}>(({ theme }) => ({
  zIndex: 1,
  display: "flex",
  borderRadius: "50%",
  justifyContent: "center",
  alignItems: "center",
  img: {
    width: "2rem",
  },
}));

function ColorlibStepIcon(props: StepIconProps) {
  const { active, className } = props;
  const icons: { [index: string]: React.ReactElement } = {
    1: <ImageComponent name={active ? "technology_active" : "technology"} />,
    2: <ImageComponent name={active ? "business_active" : "business"} />,
    3: <ImageComponent name={active ? "vision_active" : "vision"} />,
  };

  return (
    <ColorlibStepIconRoot className={className}>
      {icons[String(props.icon)]}
    </ColorlibStepIconRoot>
  );
}

export const Flavour = ({ flavours, title }: FlavourProps) => {
  const [activeStep, setActiveStep] = React.useState(0);
  return (
    <Box sx={{ maxWidth: 400 }}>
      <Typography
        variant="h4"
        color={(theme) => theme.palette.primary.main}
        mb="1rem"
        id="flavour"
        fontWeight={600}
      >
        {title}
      </Typography>
      <Stepper
        activeStep={activeStep}
        orientation="vertical"
        nonLinear
        connector={<StyledConnector />}
      >
        {flavours.map((flavour, index) => (
          <Step
            key={flavour.flavourTitle}
            sx={{
              padding: "0 1.5rem",
              paddingTop: index === 0 ? "1rem" : 0,
              background:
                activeStep === index
                  ? "transparent linear-gradient(128deg, rgba(122, 19, 21, 1) 0%, rgba(215, 25, 32, 1) 100%) 0% 0% no-repeat padding-box;"
                  : "inherit",
              borderRadius: "9px",
              paddingBottom: index === flavours.length - 1 ? "1rem" : 0,
            }}
          >
            <StyledStepLabel
              onClick={() => {
                setActiveStep(index);
              }}
              StepIconComponent={ColorlibStepIcon}
            >
              <Typography
                color={(theme) => theme.palette.secondary.light}
                variant="h6"
              >
                {flavour.flavourTitle}
              </Typography>
            </StyledStepLabel>
            <StyledStepContent
              showPad={activeStep === index && index !== flavours.length - 1}
            >
              <Typography
                color={(theme) => theme.palette.secondary.light}
                fontSize={14}
                fontWeight={300}
              >
                {flavour.flavourDescription}
              </Typography>
            </StyledStepContent>
          </Step>
        ))}
      </Stepper>
    </Box>
  );
};
