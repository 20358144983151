import { gql } from '@apollo/client';

export const GET_HOME_PAGE = gql`
  query {
    page(id: "home", idType: URI) {
      acfHome {
        homeBanners {
          bannerContent
          bannerHeading
          bannerLink
          bannerImage {
            mediaItemUrl
          }
        }
        conversationTitle
        conversationSubtitle
        flavourTitle
        flavourImage {
          srcSet
          sourceUrl
        }
        flavour {
          flavourTitle
          flavourDescription
          flavourIcon {
            mediaItemUrl
          }
        }
        intensityTitle
        intensity {
          intensityTitle
          intensityDescription
          intensityIcon {
            mediaItemUrl
          }
        }
        eventsTitle
        eventsDescription
        webinarTitle
        webinarDescription
        faqTitle
        faqs {
          answer
          questions
        }
        testimonialsDesc
        testimonialsTitle
        talkDescription
        talkTitle
        upcomingWebinars {
          ... on Webinar {
            id
            slug
            title
            featuredImage {
              node {
                mediaItemUrl
                srcSet
              }
            }
            acfPortfolio {
              upcomingWebinarRegistrationLink
            }
          }
        }
        showcaseWebinars {
          ... on Webinar {
            id
            slug
            title
            featuredImage {
              node {
                mediaItemUrl
                srcSet
              }
            }
          }
        }
      }
    }
    testimonials {
      nodes {
        title
        content
        acfTestimonials {
          company
        }
      }
    }
    portfolios {
      nodes {
        acf_taxonomy {
          featuredImage {
            altText
            sourceUrl
            srcSet
          }
        }
        slug
        name
      }
    }
    flavours {
      nodes {
        name
      }
    }
  }
`;
