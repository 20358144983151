import {
  Stack,
  Container,
  Grid,
  Card,
  CardMedia,
  useMediaQuery,
} from "@mui/material";
import { FaqProps } from "./types";
import { SectionHeader, FaqList } from "../../components";

export const Faq = ({ faqTitle, faqs }: FaqProps) => {
  const matches = useMediaQuery("(max-width:764px)");
  return (
    <div id="faqs">
      <Container
        disableGutters
        maxWidth={false}
        sx={{
          background: `transparent url('${process.env.CMS_URI}/wp-content/uploads/2023/04/Red-Black-Gradient-3-with-pattern-No-Dots.jpg') 0% 0% no-repeat padding-box`,
          paddingBottom: "4rem",
        }}
      >
        <Container maxWidth="lg">
          <Stack pt="64px">
            <SectionHeader title={faqTitle} />
            <Grid container spacing={2} justifyContent="center">
              <Grid item md={7}>
                <FaqList faqs={faqs} />
              </Grid>
              <Grid item md={5}>
                <Card
                  sx={{
                    height: matches ? "100%" : "400px",
                    backgroundColor: "inherit",
                  }}
                >
                  <CardMedia
                    component="img"
                    sx={{
                      height: matches ? "100%" : "300px",
                      objectFit: "contain",
                    }}
                    src={`${process.env.CMS_URI}/wp-content/uploads/2023/06/FAQ.png`}
                    title="Play Button"
                  />
                </Card>
              </Grid>
            </Grid>
          </Stack>
        </Container>
      </Container>
    </div>
  );
};
