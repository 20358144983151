import { WebinarPostSliderProps } from "./types";
import { Box, Button, Container, Grid, Link, Typography } from "@mui/material";
import { useQuery } from "@apollo/client";
import { useHistory } from "react-router-dom";

import Carousel from "../Carousel";

import "./styles.css";
import { GET_WEBINARS_BY_PORTFOLIO } from "../../api";
import { isEmpty } from "lodash";

const WebinarPostSlider = ({
  title,
  variant = "light",
  slug,
  count,
  categoryName,
  intensity,
  flavour,
  onViewAllClick,
}: WebinarPostSliderProps) => {
  let history = useHistory();
  const { data } = useQuery(GET_WEBINARS_BY_PORTFOLIO, {
    variables: {
      ...(slug && { portfolioSlug: [slug] }),
      ...(intensity && { intensitySlug: [intensity] }),
      ...(flavour && { flavourSlug: [flavour] }),
      ...(categoryName && { categoryName }),
      ...(count && { count }),
      categoryNotIn: "18",
    },
  });

  return (
    <>
      {!isEmpty(data?.webinars?.nodes) && (
        <Container maxWidth="lg">
          <Grid
            container
            spacing={6}
            pt={6}
            justifyContent="center"
            alignItems="center"
          >
            <Grid item xs={6}>
              <Typography
                variant="h4"
                fontWeight={600}
                color={variant === "light" ? "white" : "primary"}
              >
                {title}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              {onViewAllClick && (
                <Box justifyContent="flex-end" display="flex">
                  <Button
                    variant="outlined"
                    size="small"
                    sx={{
                      ...(variant === "light" && {
                        borderColor: "#fff",
                        color: "#fff",
                      }),
                    }}
                  >
                    View All
                  </Button>
                </Box>
              )}
            </Grid>
            <Grid item xs={11}>
              {!isEmpty(data?.webinars?.nodes) && (
                <Carousel
                  navOffset
                  navigation={false}
                  slidesPerView={3}
                  spaceBetween={30}
                  style={{ marginLeft: "2rem", marginRight: "2rem" }}
                  breakpoints={{
                    0: {
                      slidesPerView: 1,
                      spaceBetween: 10,
                      pagination: { clickable: true, type: "bullets" },
                    },
                    350: {
                      slidesPerView: 2,
                      spaceBetween: 20,
                      pagination: { clickable: true, type: "bullets" },
                    },
                    601: { slidesPerView: 3 },
                  }}
                >
                  {data?.webinars?.nodes.map((item: any, id: any) => (
                    <Box key={id}>
                      <Link
                        component="button"
                        variant="body1"
                        onClick={() => {
                          history.push(`/webinar/${item?.slug}`);
                        }}
                      >
                        <img
                          width="100%"
                          src={item?.featuredImage?.node?.sourceUrl}
                          alt={item?.featuredImage?.node?.altText || item.title}
                          srcSet={item?.featuredImage?.node?.srcSet}
                        />
                      </Link>
                    </Box>
                  ))}
                </Carousel>
              )}
            </Grid>
          </Grid>
        </Container>
      )}
    </>
  );
};

export default WebinarPostSlider;
