import {
  Stack,
  Container,
  Grid,
  Card,
  CardMedia,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import { SectionHeader, Flavour, Intensity } from "../../components";

import { ConversationProps } from "./types";

export const Conversation = ({
  conversationTitle,
  conversationSubtitle,
  flavourTitle,
  flavour,
  flavourImage,
  intensityTitle,
  intensity,
}: ConversationProps) => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <div id="flavours">
      <Container
        disableGutters
        maxWidth={false}
        sx={{
          background: `transparent url('${process.env.CMS_URI}/wp-content/uploads/2023/04/Red-Black-Gradient-3-with-pattern-No-Dots.jpg') 0% 0% no-repeat padding-box`,
          backgroundSize: "cover",
          paddingBottom: "4rem",
        }}
      >
        <Container maxWidth="lg">
          <Stack spacing={2} pt="64px">
            <SectionHeader
              title={conversationTitle}
              subtitle={conversationSubtitle}
            />
            <Grid container alignItems="center" justifyContent="center" pt={8}>
              <Grid item md={5}>
                <Flavour flavours={flavour} title={flavourTitle} />
              </Grid>
              <Grid item md={6}>
                <Card
                  sx={{
                    height: matches ? "100%" : "400px",
                    backgroundColor: "inherit",
                  }}
                >
                  <CardMedia
                    component="img"
                    sx={{ height: matches ? "100%" : "400px" }}
                    src={flavourImage.sourceUrl}
                    srcSet={flavourImage?.srcSet}
                    title="Play Button"
                  />
                </Card>
              </Grid>
            </Grid>
            <Intensity title={intensityTitle} intensities={intensity} />
          </Stack>
        </Container>
      </Container>
    </div>
  );
};
