import { gql } from "@apollo/client";

export const VIDEO_PAGE = gql`
  query ($id: ID = "") {
    webinar(id: $id, idType: SLUG) {
      slug
      title
      featuredImage {
        node {
          mediaItemUrl
          srcSet
        }
      }
      acfPortfolio {
        episode
        eventDate
        relatedVideos {
          ... on Webinar {
            id
            slug
            title
            featuredImage {
              node {
                altText
                mediaItemUrl
                srcSet
              }
            }
          }
        }
        speakerName
        speakerTitle
        year
        youtubeVideoId
      }
      flavours {
        nodes {
          name
        }
      }
      intensities {
        nodes {
          name
        }
      }
    }
  }
`;
