import { gql } from "@apollo/client";

export const SEARCH_QUERY = gql`
  query SearchQuery($search: String = "") {
    webinars(where: { search: $search }) {
      nodes {
        slug
        title
        id
      }
    }
  }
`;
