import { gql } from "@apollo/client";

export const ON_DEMAND_PAGE = gql`
  query {
    page(id: "on-demand", idType: URI) {
      acfOnDemand {
        bannerImage {
          altText
          mediaItemUrl
          srcSet
        }
        bannerWebinar {
          ... on Webinar {
            id
            title
            slug
            acfPortfolio {
              youtubeVideoId
            }
          }
        }
      }
    }
    flavours {
      nodes {
        id
        name
        slug
      }
    }
    portfolios {
      nodes {
        id
        name
        slug
      }
    }
    intensities {
      nodes {
        name
        id
        slug
      }
    }
  }
`;

export const GET_WEBINARS_BY_PORTFOLIO = gql`
  query (
    $portfolioSlug: [String] = []
    $count: Int = 3
    $categoryName: String = ""
    $flavourSlug: [String] = []
    $intensitySlug: [String] = []
    $categoryNotIn: [ID] = []
  ) {
    webinars(
      where: {
        portfolioSlug: $portfolioSlug
        intensitySlug: $intensitySlug
        flavourSlug: $flavourSlug
        categoryName: $categoryName
        categoryNotIn: $categoryNotIn
      }
      first: $count
    ) {
      nodes {
        featuredImage {
          node {
            altText
            mediaItemUrl
            sourceUrl
            srcSet
          }
        }
        slug
        title
        intensities {
          nodes {
            name
          }
        }
        flavours {
          nodes {
            name
          }
        }
        acfPortfolio {
          speakerName
          eventDate
        }
      }
    }
  }
`;
