import {
  Stack,
  Container,
  Typography,
  Box,
  useMediaQuery,
} from "@mui/material";
import Carousel from "react-material-ui-carousel";
import ChevronRight from "@mui/icons-material/ChevronRight";
import ChevronLeft from "@mui/icons-material/ChevronLeft";
import HorizontalRuleIcon from "@mui/icons-material/HorizontalRule";
import { useTheme } from "@mui/material/styles";

import { TestimonialsProps } from "./types";

export const Testimonials = ({
  testimonialsTitle,
  testimonialsDesc,
  testimonials,
}: TestimonialsProps) => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Container
      disableGutters
      maxWidth={false}
      sx={(theme) => ({
        backgroundColor: theme.palette.secondary.light,
        py: "20px",
      })}
    >
      <Container maxWidth="lg">
        <Stack
          spacing={2}
          pt="2rem"
          pb="2rem"
          sx={() => ({
            background: `transparent url('${process.env.CMS_URI}/wp-content/uploads/2023/06/eyJwYXRoIjoiZnJvbnRpZnlcL2ZpbGVcL3dyZHZkUVZ5WVU4VUJTV1FGcnhZLnBuZyJ9_frontify_W8NGMmR19xsXn5jALHe1xLgSHg2w30AcFWmgvUeDwEI.png') 50% 50% no-repeat padding-box`,
          })}
        >
          <Stack spacing={0}>
            <Typography
              variant="h4"
              color={(theme) => theme.palette.primary.main}
              fontWeight={600}
              align="center"
            >
              {testimonialsTitle}
            </Typography>
            <Typography
              variant="body2"
              fontSize={20}
              color={(theme) => theme.palette.primary.main}
              fontWeight={400}
              align="center"
            >
              {testimonialsDesc}
            </Typography>
          </Stack>
          <Carousel
            NextIcon={<ChevronRight color="inherit" />}
            PrevIcon={<ChevronLeft />}
            navButtonsAlwaysVisible
            autoPlay={false}
            navButtonsWrapperProps={{}}
            IndicatorIcon={<HorizontalRuleIcon />}
            activeIndicatorIconButtonProps={{
              style: {
                color: theme.palette.primary.main,
              },
            }}
            navButtonsProps={{
              style: {
                backgroundColor: theme.palette.primary.main,
                display: matches ? "none" : "inherit",
                fontSize: "15px",
              },
            }}
          >
            {testimonials?.nodes?.map((testimonial: any, i: number) => (
              <Stack key={i} spacing={3}>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    paddingBottom: matches ? 0 : "50px",
                  }}
                >
                  <img
                    src={`${process.env.CMS_URI}/wp-content/uploads/2023/06/Vector-Smart-Object.png`}
                    width="30px"
                    height="30px"
                    alt="testimonial_background"
                  />
                </Box>
                <Typography
                  variant="caption"
                  fontStyle="italic"
                  fontWeight={600}
                  align="center"
                >
                  <div
                    dangerouslySetInnerHTML={{
                      __html: testimonial?.content,
                    }}
                  />
                </Typography>

                <Stack spacing={0}>
                  <Typography
                    variant="body2"
                    color={(theme) => theme.palette.primary.main}
                    fontWeight={600}
                    align="center"
                  >
                    {testimonial.title}
                  </Typography>
                  <Typography
                    variant="caption"
                    color={(theme) => theme.palette.primary.main}
                    align="center"
                  >
                    {testimonial?.acfTestimonials?.company}
                  </Typography>
                </Stack>
              </Stack>
            ))}
          </Carousel>
        </Stack>
      </Container>
    </Container>
  );
};
