import { useQuery } from "@apollo/client";
import { GET_HOME_PAGE } from "../../api";
import {
  Banner,
  Conversation,
  Events,
  Webinar,
  TalkToUs,
  Testimonials,
  Faq,
} from "./containers";

import { Backdrop, CircularProgress } from "@mui/material";

function Home() {
  const { loading, error, data } = useQuery(GET_HOME_PAGE);
  const homeData = data?.page?.acfHome || {};

  if (loading)
    return (
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  if (error) return <p>Error :(</p>;
  return (
    <div>
      <Banner banners={homeData.homeBanners || []} />
      <Conversation {...homeData} />
      <Events {...homeData} events={homeData?.upcomingWebinars} />
      <Webinar {...homeData} webinars={data?.portfolios?.nodes} />
      <Testimonials {...homeData} testimonials={data?.testimonials} />
      <TalkToUs {...homeData} topics={data?.flavours?.nodes} />
      <Faq {...homeData} />
    </div>
  );
}

export default Home;
