import { useQuery } from "@apollo/client";
import {
  Box,
  Button,
  Container,
  Grid,
  Link,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { isEmpty } from "lodash";
import { useHistory } from "react-router-dom";

import { GET_WEBINARS_BY_PORTFOLIO } from "../../api";

import { WebinarRowProps } from "./types";

const WebinarRow = ({
  title,
  variant = "light",
  slug,
  count,
  categoryName,
  intensity,
  flavour,
  onViewAllClick,
}: WebinarRowProps) => {
  let history = useHistory();
  const matches = useMediaQuery("(max-width:764px)");
  const { data } = useQuery(GET_WEBINARS_BY_PORTFOLIO, {
    variables: {
      categoryNotIn: "18",
      ...(slug && { portfolioSlug: [slug] }),
      ...(intensity && { intensitySlug: [intensity] }),
      ...(flavour && { flavourSlug: [flavour] }),
      ...(categoryName && { categoryName }),
      ...(count && { count }),
    },
  });

  return (
    <>
      {!isEmpty(data?.webinars?.nodes) && (
        <Container maxWidth="lg">
          <Grid container spacing={6} pt={matches ? 2 : 6}>
            <Grid item xs={6}>
              <Typography
                variant="h4"
                fontWeight={600}
                color={variant === "light" ? "white" : "primary"}
              >
                {title}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              {onViewAllClick && (
                <Box justifyContent="flex-end" display="flex">
                  <Button
                    onClick={onViewAllClick}
                    variant="outlined"
                    size="small"
                    sx={{
                      ...(variant === "light" && {
                        borderColor: "#fff",
                        color: "#fff",
                      }),
                    }}
                  >
                    View All
                  </Button>
                </Box>
              )}
            </Grid>
            {!isEmpty(data?.webinars?.nodes) &&
              data?.webinars?.nodes.map((item: any, id: any) => (
                <Grid item xs={12} sm={6} md={4} key={id}>
                  <Link
                    component="button"
                    variant="body1"
                    onClick={() => {
                      history.push(`/webinar/${item?.slug}`);
                    }}
                  >
                    <img
                      width="100%"
                      src={item?.featuredImage?.node?.sourceUrl}
                      alt={item?.featuredImage?.node?.altText || item?.title}
                      srcSet={item?.featuredImage?.node?.srcSet}
                    />
                  </Link>
                </Grid>
              ))}
          </Grid>
        </Container>
      )}
    </>
  );
};

export default WebinarRow;
