import {
  Typography,
  Stack,
  Card,
  Grid,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { IntensityProps, IntensityItemProps } from "./types";
const IntensityCard = ({
  intensityTitle,
  intensityDescription,
  intensityIcon,
}: IntensityItemProps) => {
  return (
    <Card
      sx={{
        width: "300px",
        height: "250px",
        padding: "24px",
        borderRadius: "0.5rem",
        background:
          "transparent linear-gradient(134deg, #7A1315 0%, #D71920 100%) 0% 0% no-repeat padding-box;",
      }}
    >
      <Stack
        sx={{
          height: "100%",
          alignItems: "flex-start",
          gap: "50px",
        }}
      >
        <img
          src={intensityIcon.mediaItemUrl}
          style={{ width: "2.5rem", height: "2.5rem" }}
          alt={intensityTitle}
        />
        <Stack>
          <Typography
            variant="h5"
            color={(theme) => theme.palette.secondary.light}
          >
            {intensityTitle}
          </Typography>
          <Typography
            variant="body1"
            fontSize={14}
            fontWeight={300}
            color={(theme) => theme.palette.secondary.light}
          >
            {intensityDescription}
          </Typography>
        </Stack>
      </Stack>
    </Card>
  );
};

export const Intensity = ({ title, intensities }: IntensityProps) => {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));

  return (
    <div>
      <Stack
        direction={isDesktop ? "row" : "column"}
        mt="2rem"
        alignItems="center"
      >
        <Typography
          variant="h4"
          color={(theme) => theme.palette.secondary.light}
          align="center"
          mb="1rem"
          mr="2rem"
          fontWeight={600}
        >
          {title}
        </Typography>
        <Grid container spacing={4} alignItems="center" justifyContent="center">
          {intensities.map((it, index) => (
            <Grid item key={index} lg={4}>
              <IntensityCard {...it} />
            </Grid>
          ))}
        </Grid>
      </Stack>
    </div>
  );
};
