import {
  Stack,
  Container,
  Card,
  CardMedia,
  Typography,
  Grid,
  Link,
} from "@mui/material";
import { EventsProps } from "./types";
import { SectionHeader } from "../../components";
import { isEmpty } from "lodash";

export const Events = ({
  eventsTitle,
  eventsDescription,
  events,
}: EventsProps) => {
  return (
    <div>
      <Container disableGutters maxWidth={false}>
        <Container maxWidth="lg">
          <Stack pt="64px">
            <SectionHeader
              title={eventsTitle}
              subtitle={eventsDescription}
              id="upcoming"
            />
            {isEmpty(events) ? (
              <Typography
                pt="2rem"
                pb="4rem"
                component="p"
                textAlign="center"
                color="white"
              >
                No Upcoming events to show.
              </Typography>
            ) : (
              <Grid
                container
                pt="2rem"
                pb="4rem"
                spacing={4}
                alignItems="center"
                justifyContent="center"
              >
                {(events || []).map((event: any, ind) => (
                  <Grid item key={ind} lg={4} md={6} xs={12}>
                    <Card
                      sx={() => ({
                        borderRadius: "0.5rem",
                      })}
                    >
                      <Link
                        component="a"
                        target="_blank"
                        href={
                          event.acfPortfolio.upcomingWebinarRegistrationLink ||
                          "#"
                        }
                      >
                        <CardMedia
                          component="img"
                          sx={{ cursor: "pointer" }}
                          src={event?.featuredImage?.node?.mediaItemUrl}
                          srcSet={event?.featuredImage?.node?.srcSet}
                          title={event?.title}
                        />
                      </Link>
                    </Card>
                  </Grid>
                ))}
              </Grid>
            )}
          </Stack>
        </Container>
      </Container>
    </div>
  );
};
