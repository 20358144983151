import { gql } from "@apollo/client";

export const LATEST_WEBINARS = gql`
  query {
    webinars(last: 3) {
      nodes {
        title
        uri
        featuredImage {
          node {
            altText
            sourceUrl
          }
        }
        id
      }
    }
  }
`;

export const FLAVOUR_INTENSITY_VALUES = gql`
  query {
    flavours {
      nodes {
        id
        name
      }
    }
    intensities {
      nodes {
        name
        id
      }
    }
  }
`;
