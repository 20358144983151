import { useQuery } from "@apollo/client";

import { CATEGORY_PAGE } from "../../api";
import {
  Backdrop,
  CircularProgress,
  Box,
  Container,
  Link,
  Button,
} from "@mui/material";
import { useState } from "react";

import { useHistory, useParams } from "react-router-dom";

import { imageMap } from "../../.images";
import { WebinarRow, YoutubeBackground } from "../../components";

import { SinglePostSlider, WebinarPosts } from "./containers";

const CategoryPage = () => {
  let history = useHistory();
  const { portfolioName } = useParams<{ portfolioName: string }>();
  const [isPlaying, setPlaying] = useState(false);
  const { loading, data } = useQuery(CATEGORY_PAGE, {
    variables: {
      id: portfolioName,
    },
  });

  return (
    <>
      {loading ? (
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={loading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      ) : (
        <>
          {isPlaying ? (
            <YoutubeBackground
              videoId={
                data?.portfolio?.acf_taxonomy?.featurePost?.acfPortfolio
                  ?.youtubeVideoId
              }
            />
          ) : (
            <Box position="relative">
              <Link
                component="button"
                variant="body1"
                onClick={() => {
                  history.push(
                    `/webinar/${data?.portfolio?.acf_taxonomy?.featurePost?.slug}`
                  );
                }}
              >
                <img
                  width="100%"
                  src={
                    data?.portfolio?.acf_taxonomy?.featuredWebinarImage
                      ?.sourceUrl ||
                    data?.portfolio?.acf_taxonomy?.featurePost?.featuredImage
                      ?.node?.sourceUrl
                  }
                  alt={data?.portfolio?.acf_taxonomy?.featurePost?.title}
                  srcSet={
                    data?.portfolio?.acf_taxonomy?.featuredWebinarImage
                      ?.srcSet ||
                    data?.portfolio?.acf_taxonomy?.featurePost?.featuredImage
                      ?.node?.srcSet
                  }
                />
              </Link>
              <Button
                sx={{
                  position: "absolute",
                  right: 0,
                  left: 0,
                  top: 0,
                  bottom: 0,
                }}
                onClick={() => setPlaying((prev) => !prev)}
              >
                <img
                  src={`${process.env.CMS_URI}/wp-content/uploads/2023/07/Play-button.png`}
                  alt="thumbnail"
                  style={{
                    width: "100px",
                    height: "100px",
                    marginRight: "0",
                    cursor: "pointer",
                  }}
                />
              </Button>
            </Box>
          )}

          <Box
            sx={{
              "&:before": {
                content: '""',
                position: "absolute",
                top: 0,
                width: "100%",
                height: "100%",
                zIndex: -1,
                bottom: 0,
                backgroundImage: `url(${imageMap[
                  "Red-Black-Gradient-3-with-pattern"
                ]()})`,
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
                backgroundPosition: "bottom right",
                transform: "rotateY(180deg)",
              },
              py: 6,
              position: "relative",
              zIndex: 1,
            }}
          >
            <WebinarRow title="Latest" slug={portfolioName} count={3} />
          </Box>
          <SinglePostSlider slug={portfolioName} title="Upcoming" />
          <Container maxWidth="lg" sx={{ py: 6 }}>
            <WebinarPosts
              slug={portfolioName}
              flavours={data?.flavours?.nodes}
              intensities={data?.intensities?.nodes}
            />
          </Container>
        </>
      )}
    </>
  );
};

export default CategoryPage;
