import React from "react";
import {
  AppBar,
  Box,
  Button,
  Container,
  Drawer,
  IconButton,
  Link,
  List,
  ListItem,
  ListItemButton,
  MenuItem,
  Stack,
  Toolbar,
  useMediaQuery,
} from "@mui/material";

import { styled } from "@mui/system";
import DehazeIcon from "@mui/icons-material/Dehaze";
import { useTheme } from "@mui/material/styles";
import { NavHashLink } from "react-router-hash-link";

import { menuItems } from "../../config/menu";
import { imageMap } from "../../.images";

const drawerWidth = 240;

const scrollWithOffset = (el: HTMLElement, offset: number) => {
  setTimeout(() => {
    const elementPosition = el.offsetTop - offset;
    window.scroll({
      top: elementPosition,
      left: 0,
      behavior: "smooth",
    });
  }, 100);
};

const StyleMenuItem = styled(MenuItem)(
  ({ theme }) => `
  & .MuiMenuItem-root {
    color: ${theme.palette.primary.main};
    borderBottom: " 2px solid transparent";
    :hover {
      background: "#2b2b2b",
      borderColor: ${theme.palette.primary.main},
    }
  }
`
);

export const Header = (props: any) => {
  const { window, showSearch, onClickSearch } = props;

  const container =
    window !== undefined ? () => window().document.body : undefined;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.down("xl"));
  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: "center" }}>
      <List>
        {menuItems.map((item, i) => (
          <ListItem
            key={item.key}
            disablePadding
            sx={{
              borderBottom:
                i + 1 === menuItems.length
                  ? "none"
                  : "1px solid rgb(255 255 255 / 23%)",
              padding: "1rem",
            }}
          >
            <ListItemButton
              component="span"
              sx={{ textAlign: "center", fontSize: 16, fontWeight: 600 }}
            >
              <NavHashLink
                style={{ color: "#fff", textDecoration: "none" }}
                key={item.key}
                to={item.url}
                scroll={(el) =>
                  scrollWithOffset(el, item.mobileOffset || item.offset || 0)
                }
                activeClassName="activelink"
                activeStyle={{ color: theme.palette.secondary.main }}
              >
                {item.label}
              </NavHashLink>
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );

  return (
    <>
      <Box sx={{ display: "flex" }}>
        <Container disableGutters maxWidth={false}>
          <AppBar sx={{ background: "#000", boxShadow: "none" }}>
            <Container
              maxWidth="lg"
              disableGutters={isDesktop ? false : true}
              sx={{ py: "4px" }}
            >
              <Toolbar disableGutters>
                <Box
                  sx={{
                    justifyContent: "flex-end",
                    alignItems: "flex-end",
                    paddingTop: "10px",
                  }}
                >
                  <a href="/" title="Home">
                    <img
                      width={100}
                      src={imageMap["TRENDsCampus-logo"]()}
                      alt="Trendcampus Logo"
                    />
                  </a>
                </Box>
                {showSearch ? (
                  <Box
                    sx={{
                      display: { xs: "none", sm: "none", md: "flex" },
                      justifyContent: "flex-end",
                      alignItems: "flex-end",
                      flexGrow: 1,
                      paddingBottom: "10px",
                    }}
                  >
                    <Box p="10px 8px">
                      <Link component="button" onClick={onClickSearch}>
                        <div style={{ cursor: "pointer", width: "15px" }}>
                          X
                        </div>
                      </Link>
                    </Box>
                  </Box>
                ) : (
                  <>
                    <Box
                      sx={{
                        display: { xs: "none", sm: "none", md: "flex" },
                        justifyContent: "flex-end",
                        alignItems: "flex-end",
                        flexGrow: 1,
                        paddingBottom: "10px",
                      }}
                    >
                      {menuItems.map((item, i) => (
                        <StyleMenuItem
                          key={i}
                          sx={{
                            textAlign: "center",
                            paddingLeft: "10px",
                            paddingRight: "10px",
                            fontSize: 14,
                            fontWeight: 600,
                            textTransform: "capitalize",
                          }}
                        >
                          <NavHashLink
                            smooth
                            scroll={(el) =>
                              scrollWithOffset(el, item.offset || 0)
                            }
                            style={{ color: "#fff", textDecoration: "none" }}
                            key={item.key}
                            to={item.url}
                            activeClassName="activelink"
                          >
                            {item.label}
                          </NavHashLink>
                        </StyleMenuItem>
                        // <Button
                        //   key={item.key}
                        //   href={item.url}
                        //   sx={{
                        //     color: "#fff",
                        //     textAlign: "center",
                        //     fontSize: 14,
                        //     fontWeight: 600,
                        //     textTransform: "capitalize",
                        //   }}
                        // >
                        //   {item.label}
                        // </Button>
                      ))}
                      <Box p="6px 8px">
                        <Button
                          variant="contained"
                          href="/#contact"
                          sx={{
                            textTransform: "capitalize",
                            fontSize: 14,
                            height: "30px",
                          }}
                        >
                          Contact Us
                        </Button>
                      </Box>
                      <Box p="10px 8px">
                        <Stack alignItems="flex-end">
                          <Link component="button" onClick={onClickSearch}>
                            <img
                              width={15}
                              src={`${process.env.CMS_URI}/wp-content/uploads/2023/06/search.png`}
                              alt="Search"
                              style={{ cursor: "pointer" }}
                            />
                          </Link>
                          <img
                            width={120}
                            src={imageMap["Trend Micro_Logo"]()}
                            alt="Trendcampus Logo"
                          />
                        </Stack>
                      </Box>
                    </Box>

                    <Box
                      sx={{ display: { xs: "flex", sm: "flex", md: "none" } }}
                      justifyContent="flex-end"
                      flexGrow={1}
                    >
                      <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        edge="start"
                        onClick={handleDrawerToggle}
                        sx={{
                          mr: 2,
                        }}
                      >
                        <DehazeIcon />
                      </IconButton>
                      <Link component="button" onClick={onClickSearch}>
                        <img
                          width={15}
                          src={`${process.env.CMS_URI}/wp-content/uploads/2023/06/search.png`}
                          alt="Search"
                          style={{ cursor: "pointer" }}
                        />
                      </Link>
                    </Box>
                  </>
                )}
              </Toolbar>
            </Container>
          </AppBar>
        </Container>
      </Box>
      <Box component="nav">
        <Drawer
          container={container}
          variant="temporary"
          anchor="right"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true,
          }}
          sx={{
            display: { xs: "block", sm: "block", md: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
              background:
                "transparent linear-gradient(318deg, #7A1315 0%, #D71920 100%) 0% 0% no-repeat padding-box",
            },
          }}
        >
          {drawer}
        </Drawer>
      </Box>
    </>
  );
};
