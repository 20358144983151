import React, { useEffect, useRef, useState } from "react";
import { VolumeUp, VolumeOff } from "@mui/icons-material";
import YouTube from "react-youtube";
import { IconButton, useTheme } from "@mui/material";

import "./styles.css";
import { YoutubeBackgroundProps } from "./types";

const YoutubeBackground = (props: YoutubeBackgroundProps) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const theme = useTheme();
  const playerRef = useRef<any>(null);
  const [isMuted, setIsMuted] = useState(false);
  const defaultPlayerVars = {
    autoplay: 1,
    controls: 0,
    rel: 0,
    mute: 0,
    modestbranding: 1,
    iv_load_policy: 3,
    playsinline: 1,
    loop: 0,
    enablejsapi: 1,
    autoHide: 1,
  };

  const updateDimensions = () => {
    const { current: container } = containerRef;
    if (!container) return;
  };

  useEffect(() => {
    updateDimensions();
    window.addEventListener("resize", updateDimensions);
    return () => {
      window.removeEventListener("resize", updateDimensions);
    };
  }, []);

  const onEnd = (event: any) => {
    event.target.playVideo();
    props.onEnd && props.onEnd(event);
  };

  const { overlay, playerOptions } = props;
  const playerProps = {
    videoId: props.videoId,
  };

  const videoOptions: { playerVars: any; host: string } = {
    playerVars: {
      ...defaultPlayerVars,
      ...playerOptions,
    },
    host: props.nocookie
      ? "https://www.youtube-nocookie.com"
      : "https://www.youtube.com",
  };

  const handleToggleMute = () => {
    const player = playerRef?.current;
    setIsMuted(!isMuted);
    if (isMuted) {
      player?.unMute();
    } else {
      player?.mute();
    }
  };

  return (
    <div ref={containerRef} className="bg-videoContainer">
      <div
        className="videoContainer"
        style={
          {
            // width: videoWidth + "px",
            // height: videoHeight + "px",
            // top: videoY + "px",
            // left: videoX + "px",
          }
        }
      >
        {overlay && (
          <div className="overlay" style={{ backgroundColor: overlay }}></div>
        )}
        <YouTube
          {...playerProps}
          onReady={(event) => {
            playerRef.current = event.target;
          }}
          onEnd={onEnd}
          opts={videoOptions}
          className="videoIframe"
          iframeClassName="videoInnerContainer"
        />
      </div>
      <IconButton
        aria-label="delete"
        size="small"
        className="mute-button"
        onClick={handleToggleMute}
        sx={{ color: theme.palette.grey[800] }}
      >
        {isMuted ? (
          <VolumeOff fontSize="medium" />
        ) : (
          <VolumeUp fontSize="medium" />
        )}
      </IconButton>
    </div>
  );
};

export default YoutubeBackground;
