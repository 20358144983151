export const menuItems = [
  { label: "The Story", key: "the-story", url: "/" },
  {
    label: "Flavours",
    key: "flavour",
    url: "/#flavours",
    offset: -100,
    mobileOffset: -250,
  },
  {
    label: "Upcoming",
    key: "upcoming",
    url: "/#upcoming",
    offset: 150,
    mobileOffset: 120,
  },
  { label: "On Demand", key: "on-demand", url: "/on-demand", offset: 100 },
  { label: "FAQs", key: "faqs", url: "/#faqs", offset: 100 },
];

export const legalItems = [
  { label: "Privacy Policy", url: "/privacy-policy" },
  { label: "Legal", url: "/legal" },
];
