import { Container, Typography, Grid, Button, Box } from "@mui/material";
import { useQuery } from "@apollo/client";
import useMediaQuery from "@mui/material/useMediaQuery";

import { Carousel } from "../../../../components";
import { GET_WEBINARS_BY_PORTFOLIO } from "../../../../api";

import { SinglePostSliderProps } from "./types";

export const SinglePostSlider = ({ slug, title }: SinglePostSliderProps) => {
  const matches = useMediaQuery("(max-width:764px)");
  const { data } = useQuery(GET_WEBINARS_BY_PORTFOLIO, {
    variables: {
      count: 5,
      categoryName: "upcoming",
      ...(slug && { portfolioSlug: [slug] }),
    },
  });

  return (
    <Container
      maxWidth={false}
      disableGutters
      sx={(theme) => ({
        backgroundColor: theme.palette.secondary.light,
        paddingTop: matches ? 6 : 10,
        paddingBottom: 10,
      })}
    >
      <Container maxWidth="lg">
        <Grid container pb={7}>
          <Grid item>
            <Typography variant="h4" color="primary" fontWeight={600}>
              {title}
            </Typography>
          </Grid>
        </Grid>
        {data?.webinars?.nodes.length > 0 ? (
          <Carousel
            style={{
              marginLeft: matches ? 0 : "3rem",
              marginRight: matches ? 0 : "3rem",
            }}
            navOffset
            navigation={false}
            slidesPerView={1}
            spaceBetween={30}
            breakpoints={{
              0: {
                pagination: { clickable: true, type: "bullets" },
              },
              350: {
                pagination: { clickable: true, type: "bullets" },
              },
            }}
            loop
          >
            {data?.webinars?.nodes.map((item: any, i: number) => (
              <Grid container key={i} spacing={6}>
                <Grid item xs={12} sm={12} md={5} py={3}>
                  <Typography
                    variant="h3"
                    fontWeight={600}
                    pb={3}
                    color="primary"
                    fontSize="2.6rem"
                  >
                    {item.title}
                  </Typography>
                  <Typography pb={0.5}>
                    Author: {item?.acfPortfolio.speakerName}
                  </Typography>
                  <Typography pb={0.5}>
                    Date: {item?.acfPortfolio.eventDate}
                  </Typography>
                  <Typography pb={0.5}>
                    Flavour:{" "}
                    {item?.flavours?.nodes
                      .map((item: { name: any }) => item.name)
                      .join("")}
                  </Typography>
                  <Typography pb={2}>
                    Intensity:{" "}
                    {item?.intensities?.nodes
                      .map((item: { name: any }) => item.name)
                      .join("")}
                  </Typography>
                  <Button
                    variant="contained"
                    size="small"
                    onClick={() => {}}
                    sx={{ textTransform: "capitalize" }}
                  >
                    Register Now
                  </Button>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={7}
                  py={3}
                  alignItems="center"
                  justifyContent={"center"}
                >
                  <Box>
                    <img
                      style={{
                        height: matches ? "100%" : "400px",
                        objectFit: "contain",
                        width: "100%",
                      }}
                      width="100%"
                      alt=""
                      src={item?.featuredImage?.node?.sourceUrl}
                    />
                  </Box>
                </Grid>
              </Grid>
            ))}
          </Carousel>
        ) : (
          <>
            <Typography>No upcoming webinars</Typography>
          </>
        )}
      </Container>
    </Container>
  );
};
