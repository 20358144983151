import { RelatedVideosProps } from "./types";
import { Box, Container, Grid, Typography } from "@mui/material";
import { useHistory } from "react-router-dom";

import Carousel from "../../components/Carousel";

import { isEmpty } from "lodash";

export const RelatedVideos = ({ title, videos }: RelatedVideosProps) => {
  let history = useHistory();
  return (
    <>
      {!isEmpty(videos) && (
        <Container maxWidth="lg">
          <Grid
            container
            spacing={6}
            pt={6}
            justifyContent="center"
            alignItems="center"
          >
            <Grid item xs={11}>
              <Typography variant="h4" color="white" align="center">
                {title}
              </Typography>
            </Grid>
            <Grid item xs={11}>
              {!isEmpty(videos) && (
                <Carousel
                  navOffset
                  navigation={false}
                  slidesPerView={3}
                  spaceBetween={30}
                  breakpoints={{
                    0: {
                      slidesPerView: 1,
                      spaceBetween: 10,
                      pagination: { clickable: true, type: "bullets" },
                    },
                    350: {
                      slidesPerView: 2,
                      spaceBetween: 20,
                      pagination: { clickable: true, type: "bullets" },
                    },
                    601: { slidesPerView: 3 },
                  }}
                  loop
                >
                  {videos.map((item: any, id: any) => (
                    <Box key={id}>
                      <img
                        width="100%"
                        src={item?.featuredImage?.node?.srcSet}
                        alt={item?.featuredImage?.node?.altText || item.title}
                        srcSet={item?.featuredImage?.node?.srcSet}
                        onClick={() => {
                          history.push(`/webinar/${item.slug}`);
                        }}
                      />
                    </Box>
                  ))}
                </Carousel>
              )}
            </Grid>
          </Grid>
        </Container>
      )}
    </>
  );
};
