import { useQuery } from "@apollo/client";
import {
  Container,
  Grid,
  Link,
  MenuItem,
  Pagination,
  Skeleton,
  TextField,
  styled,
} from "@mui/material";
import { useHistory } from "react-router-dom";
import { isEmpty, range } from "lodash";

import { CATEGORY_POST_WITH_PAGINATION } from "../../../../api";

import { WebinarPostsProps } from "./types";
import "./styles.css";
import { useEffect, useState } from "react";

const SIZE = 9;

const DropDownField = styled(TextField)({
  "& .MuiInputBase-root": {
    background: "#fff;",
  },
});

const WebinarPosts = ({ slug, flavours, intensities }: WebinarPostsProps) => {
  let history = useHistory();
  const [page, setPage] = useState(0);
  const [flavour, setFlavour] = useState("");
  const [intensity, setIntensity] = useState("");

  const { data, fetchMore, loading } = useQuery(CATEGORY_POST_WITH_PAGINATION, {
    variables: {
      ...(slug && { portfolioSlug: [slug] }),
      ...(intensity && { intensitySlug: [intensity] }),
      ...(flavour && { flavourSlug: [flavour] }),
      offset: page * SIZE,
      size: SIZE,
    },
  });

  useEffect(() => {
    fetchMore({
      variables: {
        ...(slug && { portfolioSlug: [slug] }),
        ...(intensity && { intensitySlug: [intensity] }),
        ...(flavour && { flavourSlug: [flavour] }),

        offset: page * SIZE,
        size: SIZE,
      },
    });
  }, [page, intensity, flavour, slug, fetchMore]);

  return (
    <>
      <Container maxWidth="lg">
        <Grid container spacing={4} justifyContent="center">
          <Grid item xs={12} sm={4} md={4}>
            <DropDownField
              fullWidth
              id="flavour"
              size="small"
              select
              onChange={(event) => {
                setPage(0);
                setFlavour(event.target.value);
              }}
              value={flavour}
              label="Select Flavour"
            >
              {flavours?.map((option: any) => (
                <MenuItem key={option.id} value={option.slug}>
                  {option.name}
                </MenuItem>
              ))}
            </DropDownField>
          </Grid>
          <Grid item xs={12} sm={4} md={4}>
            <DropDownField
              fullWidth
              id="Intensity"
              size="small"
              select
              onChange={(event) => {
                setPage(0);
                setIntensity(event.target.value);
              }}
              value={intensity}
              label="Select Intensity"
            >
              {intensities?.map((option: any) => (
                <MenuItem key={option.id} value={option.slug}>
                  {option.name}
                </MenuItem>
              ))}
            </DropDownField>
          </Grid>
        </Grid>
        {loading ? (
          <>
            <Grid container spacing={6} pt={6}>
              {range(4).map((_, id: any) => (
                <Grid item xs={12} sm={6} md={4} key={id}>
                  <Skeleton
                    sx={{ backgroundColor: "rgba(255, 255, 255, 0.13)" }}
                    variant="rectangular"
                    width={336}
                    height={189}
                  />
                </Grid>
              ))}
            </Grid>
          </>
        ) : (
          <>
            {!isEmpty(data?.webinars?.nodes) && (
              <Grid container spacing={6} pt={6}>
                {!isEmpty(data?.webinars?.nodes) &&
                  data?.webinars?.nodes.map((item: any, id: any) => (
                    <Grid item xs={12} sm={6} md={4} key={id}>
                      <Link
                        component="button"
                        variant="body1"
                        onClick={() => {
                          history.push(`/webinar/${item?.slug}`);
                        }}
                      >
                        <img
                          width="100%"
                          src={item?.featuredImage?.node?.sourceUrl}
                          alt={
                            item?.featuredImage?.node?.altText || item?.title
                          }
                          srcSet={item?.featuredImage?.node?.srcSet}
                        />
                      </Link>
                    </Grid>
                  ))}
              </Grid>
            )}
          </>
        )}

        {data?.webinars?.pageInfo?.offsetPagination?.total > 10 && (
          <Grid container spacing={6} pt={6}>
            <Grid item xs={12}>
              <Pagination
                page={page + 1}
                count={Math.ceil(
                  data?.webinars?.pageInfo?.offsetPagination?.total / 10 || 0
                )}
                color="primary"
                onChange={(_, page: number) => setPage(page - 1)}
              />
            </Grid>
          </Grid>
        )}
      </Container>
    </>
  );
};

export default WebinarPosts;
