import { Typography, Stack, Container } from "@mui/material";
import { SectionHeaderProps } from "./types";

export const SectionHeader = ({
  title = "",
  subtitle = "",
  id = "",
}: SectionHeaderProps) => {
  return (
    <Container id={id} maxWidth="md" sx={{ padding: "0 1rem" }}>
      <Stack spacing={0}>
        <Typography
          variant="h4"
          color={(theme) => theme.palette.secondary.light}
          align="center"
          mb="1rem"
          fontWeight={600}
        >
          {title}
        </Typography>
        <div>
          <Typography
            variant="body2"
            color={(theme) => theme.palette.secondary.light}
            align="center"
          >
            {subtitle}
          </Typography>
        </div>
      </Stack>
    </Container>
  );
};
