import Carousel from 'react-material-ui-carousel';
import {
  Box,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import ChevronRight from '@mui/icons-material/ChevronRight';
import ChevronLeft from '@mui/icons-material/ChevronLeft';
import { BannerProps } from './types';
import { useState } from 'react';
import { socialMediaicons } from '../../../../config/icon';

export const Banner = ({ banners }: BannerProps) => {
  const [firstImgLoaded, setFirstImgLoaded] = useState(false);
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <div id='story'>
      <img
        src={banners[0]?.bannerImage?.mediaItemUrl}
        alt='img'
        onLoad={() => setFirstImgLoaded(true)}
        style={{ display: 'none' }}
      />
      {firstImgLoaded && (
        <div style={{ position: 'relative' }}>
          <Carousel
            NextIcon={<ChevronRight />}
            PrevIcon={<ChevronLeft />}
            indicators={false}
            navButtonsAlwaysVisible
            autoPlay={false}
            swipe={false}
            navButtonsProps={{ style: { padding: matches ? '0' : '8px' } }}
            sx={{ overflowY: 'visible', overflowX: 'clip' }}
          >
            {banners.map((banner, i: number) => (
              <Box
                key={i}
                component='img'
                {...(banner?.bannerLink && {
                  onClick: () => window.open(banner?.bannerLink),
                  style: { cursor: 'pointer' },
                })}
                sx={{
                  height: '100%',
                  width: '100%',
                }}
                alt={banner.bannerHeading}
                src={banner.bannerImage?.mediaItemUrl}
              />
            ))}
          </Carousel>
          <Box
            sx={(theme) => ({
              position: 'fixed',
              right: 0,
              bottom: '10px',
              zIndex: 1,
              background: theme.palette.secondary.dark,
            })}
          >
            <List
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: 0,
                py: 0,
              }}
            >
              {socialMediaicons.map((Icon, ind) => (
                <ListItem
                  sx={{ py: 0, px: '0.5rem', width: 'inherit' }}
                  key={ind}
                >
                  <ListItemButton
                    sx={{ pl: 0 }}
                    href={Icon.url}
                    target='_blank'
                  >
                    <ListItemIcon
                      className='sticky-social-icons'
                      sx={{ px: 0 }}
                    >
                      <Icon.StickyIcon htmlColor='#fff' fontSize='small' />
                    </ListItemIcon>
                  </ListItemButton>
                </ListItem>
              ))}
            </List>
          </Box>
        </div>
      )}
    </div>
  );
};
