import { gql } from "@apollo/client";

export const CATEGORY_POST_WITH_PAGINATION = gql`
  query (
    $offset: Int = 0
    $size: Int = 10
    $portfolioSlug: [String] = []
    $flavourSlug: [String] = []
    $intensitySlug: [String] = []
  ) {
    webinars(
      where: {
        offsetPagination: { offset: $offset, size: $size }
        categoryNotIn: "18"
        portfolioSlug: $portfolioSlug
        intensitySlug: $intensitySlug
        flavourSlug: $flavourSlug
      }
    ) {
      pageInfo {
        offsetPagination {
          hasMore
          hasPrevious
          total
        }
      }
      nodes {
        slug
        title
        featuredImage {
          node {
            srcSet
            sourceUrl
          }
        }
      }
    }
  }
`;

export const CATEGORY_PAGE = gql`
  query ($id: ID! = "") {
    portfolio(id: $id, idType: SLUG) {
      acf_taxonomy {
        featurePost {
          ... on Webinar {
            id
            title
            slug
            featuredImage {
              node {
                sourceUrl
                srcSet
              }
            }
            acfPortfolio {
              youtubeVideoId
            }
          }
        }
        featuredWebinarImage {
          sourceUrl
          srcSet
        }
      }
    }
    flavours {
      nodes {
        id
        name
        slug
      }
    }
    intensities {
      nodes {
        name
        id
        slug
      }
    }
  }
`;
