import React from "react";
import { Switch, Route, withRouter } from "react-router-dom";
import { AnimatePresence } from "framer-motion";

import { Home, OnDemand, CategoryPage, VideoPage } from "../pages";

class ScrollToTop extends React.Component<any> {
  public componentDidUpdate(prevProps: any) {
    if (this.props.location !== prevProps.location) {
      window.scrollTo(0, 0);
    }
  }
  public render() {
    const { children, location } = this.props;
    return React.cloneElement(children as any, {
      location,
    });
  }
}
const ScrollToTopHandler = withRouter(ScrollToTop);

export const Routes: React.FC = () => {
  return (
    <ScrollToTopHandler>
      <AnimatePresence>
        <Switch>
          <Route exact path="/" component={Home} />
          <Route exact path="/on-demand" component={OnDemand} />
          <Route
            exact
            path="/category/:portfolioName"
            component={CategoryPage}
          />
          <Route exact path="/webinar/:videoId" component={VideoPage} />
          {/* Add more routes here */}
        </Switch>
      </AnimatePresence>
    </ScrollToTopHandler>
  );
};
