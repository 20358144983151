import { Swiper, SwiperSlide } from "swiper/react";
import { ChevronLeft, ChevronRight } from "@mui/icons-material";
import { Box, IconButton } from "@mui/material";
import { Swiper as SwiperType, Pagination } from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { useRef } from "react";

import "./styles.css";
import { CarouselProps } from "./types";

const Carousel = ({ children, navOffset, ...props }: CarouselProps) => {
  const swiperRef = useRef<SwiperType>();
  return (
    <Box sx={{ position: "relative" }}>
      <Swiper
        modules={[Pagination]}
        onBeforeInit={(swiper: SwiperType) => {
          swiperRef.current = swiper;
        }}
        pagination={{
          el: ".custom-pagination-div",
          clickable: true,
          renderBullet: (index, className) => {
            return '<span class="' + className + '">' + index + "</span>";
          },
        }}
        {...props}
      >
        {children.map((item, id) => (
          <SwiperSlide key={id}>{item}</SwiperSlide>
        ))}
      </Swiper>
      <Box sx={{ "@media screen and (max-width: 600px)": { display: "none" } }}>
        <IconButton
          sx={(theme) => ({
            top: "50%",
            position: "absolute",
            left: navOffset ? "-3%" : "1%",
            transform: "translateY(-50%)",
            border: `1px solid ${theme.palette.primary.main}`,
            fontSize: "1rem",
            padding: "6px",
            zIndex: 9,
          })}
          color="primary"
          onClick={() => swiperRef.current?.slidePrev()}
        >
          <ChevronLeft />
        </IconButton>
        <IconButton
          sx={(theme) => ({
            right: navOffset ? "-3%" : "1%",
            top: "50%",
            transform: "translateY(-50%)",
            position: "absolute",
            border: `1px solid ${theme.palette.primary.main}`,
            fontSize: "1rem",
            padding: "6px",
            zIndex: 9,
          })}
          color="primary"
          onClick={() => swiperRef.current?.slideNext()}
        >
          <ChevronRight />
        </IconButton>
      </Box>
      <Box className="custom-pagination-div"></Box>
    </Box>
  );
};

export default Carousel;
