import { useHistory } from "react-router-dom";
import { Container, Card, Grid, Box } from "@mui/material";

import { SectionHeader } from "../../components";

import { WebinarProps } from "./types";

export const Webinar = ({
  webinarTitle,
  webinarDescription,
  webinars,
}: WebinarProps) => {
  let history = useHistory();
  return (
    <Container
      disableGutters
      maxWidth={false}
      sx={{
        background: `transparent url('${process.env.CMS_URI}/wp-content/uploads/2023/06/Red-Black-Gradient-3-with-pattern-No-Dots.jpg') 0% 0% no-repeat padding-box`,
        backgroundSize: "cover",
      }}
    >
      <Box pt="4rem">
        <SectionHeader title={webinarTitle} subtitle={webinarDescription} />
      </Box>
      <Container maxWidth="lg">
        <Grid container spacing={3} py={8}>
          {(webinars || []).map((webinar: any, ind) => (
            <Grid item xs={12} sm={4} md={4} key={ind}>
              <Card
                sx={{
                  background: "none",
                  boxShadow: "none",
                  cursor: "pointer",
                }}
                key={ind}
                onClick={() => history.push(`/category/${webinar.slug}`)}
              >
                <img
                  alt={webinar?.name}
                  width="100%"
                  src={webinar?.acf_taxonomy?.featuredImage?.mediaItemUrl}
                  srcSet={webinar?.acf_taxonomy?.featuredImage?.srcSet}
                  title={webinar?.name}
                />
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Container>
  );
};
