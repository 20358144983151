import React from "react";
import {
  AppBar,
  Box,
  Container,
  Divider,
  Link,
  List,
  ListItem,
  ListItemText,
  TextField,
  Toolbar,
  useMediaQuery,
} from "@mui/material";
import { useLazyQuery } from "@apollo/client";

import { imageMap } from "../../.images";
import { SEARCH_QUERY } from "../../api";

export const Search = ({ onClickSearch, showSearch }: any) => {
  const [search, setSearch] = React.useState("");
  const [searchQuery, { data }] = useLazyQuery(SEARCH_QUERY, {
    fetchPolicy: "network-only",
  });

  React.useEffect(() => {
    searchQuery({ variables: { search } });
  }, [search, searchQuery]);

  React.useEffect(() => {
    if (!showSearch) {
      setSearch("");
    }
  }, [showSearch]);

  const webinars = data?.webinars?.nodes || [];
  const matches = useMediaQuery("(max-width:764px)");
  return (
    <>
      <Box sx={{ display: "flex" }}>
        <Container disableGutters maxWidth={false}>
          <AppBar sx={{ background: "#000", boxShadow: "none" }}>
            <Container maxWidth="lg" disableGutters={false} sx={{ py: "4px" }}>
              <Toolbar disableGutters>
                <Box
                  sx={{
                    justifyContent: "flex-end",
                    alignItems: "flex-end",
                    paddingTop: "10px",
                  }}
                >
                  <a href="/" title="Home">
                    <img
                      width={100}
                      src={imageMap["TRENDsCampus-logo"]()}
                      alt="Trendcampus Logo"
                    />
                  </a>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "flex-end",
                    flexGrow: 1,
                    paddingBottom: "10px",
                  }}
                >
                  <Box p="10px 8px">
                    <Link component="button" onClick={onClickSearch}>
                      <div style={{ cursor: "pointer", width: "15px" }}>X</div>
                    </Link>
                  </Box>
                </Box>
              </Toolbar>
            </Container>
          </AppBar>
          <Container
            maxWidth="lg"
            disableGutters={false}
            sx={{ py: "4px", position: "relative" }}
          >
            <TextField
              color="error"
              error
              placeholder="Search"
              variant="standard"
              value={search}
              onChange={(event) => {
                setSearch(event.target.value);
              }}
              fullWidth
              sx={(theme) => ({
                width: matches ? "300px" : "600px",
                ".MuiInputBase-input": {
                  color: "white",
                },
              })}
            />
            <div style={{ position: "absolute" }}>
              {webinars.length > 0 && (
                <List
                  sx={{
                    width: "100%",
                    bgcolor: "background.paper",
                    maxHeight: "200px",
                    overflow: "scroll",
                  }}
                >
                  {webinars.map((web: any, ind: number) => (
                    <div key={ind}>
                      <Link
                        component="a"
                        variant="body1"
                        href={`/webinar/${web?.slug}`}
                        sx={{ textDecoration: "none" }}
                      >
                        <ListItem>
                          <ListItemText
                            sx={{ color: "black" }}
                            primary={web.title}
                          />
                        </ListItem>
                      </Link>
                      {ind < webinars.length && <Divider />}
                    </div>
                  ))}
                </List>
              )}
            </div>
          </Container>
        </Container>
      </Box>
    </>
  );
};
