import { useQuery } from "@apollo/client";
import { useHistory } from "react-router-dom";
import { isEmpty } from "lodash";
import { useMemo, useState } from "react";
import { styled } from "@mui/material/styles";

import { ON_DEMAND_PAGE } from "../../api";
import {
  Backdrop,
  CircularProgress,
  Box,
  Grid,
  MenuItem,
  TextField,
  Container,
  Link,
  Button,
  useMediaQuery,
  useTheme,
} from "@mui/material";

import {
  WebinarPostSlider,
  WebinarRow,
  YoutubeBackground,
} from "../../components";
import { imageMap } from "../../.images";

import "./styles.css";

const DropDownField = styled(TextField)({
  "& .MuiInputBase-root": {
    background: "#fff;",
  },
});

const OnDemand = () => {
  const { loading, data } = useQuery(ON_DEMAND_PAGE);
  let history = useHistory();

  const [portfolios, setPortfolio] = useState("");
  const [intensities, setIntensities] = useState("");
  const [flavours, setFlavours] = useState("");
  const [isPlaying, setPlaying] = useState(false);
  const theme = useTheme();

  const matches = useMediaQuery(theme.breakpoints.down("sm"));

  const filteredPortfolios = useMemo(() => {
    if (!portfolios || !data?.portfolios?.nodes) {
      return data?.portfolios?.nodes || [];
    }
    return data.portfolios.nodes.filter(
      (item: any) => item.slug === portfolios
    );
  }, [portfolios, data?.portfolios?.nodes]);

  return (
    <>
      {loading ? (
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={loading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      ) : (
        <>
          <Box sx={{ position: "relative" }}>
            {isPlaying ? (
              <YoutubeBackground
                videoId={
                  data?.page?.acfOnDemand?.bannerWebinar?.acfPortfolio
                    ?.youtubeVideoId
                }
              />
            ) : (
              <>
                <Link
                  component="button"
                  variant="body1"
                  onClick={() => setPlaying((prev) => !prev)}
                >
                  <img
                    width="100%"
                    src={data?.page?.acfOnDemand?.bannerImage?.sourceUrl}
                    alt={data?.page?.acfOnDemand?.bannerImage?.altText}
                    srcSet={data?.page?.acfOnDemand?.bannerImage?.srcSet}
                  />
                </Link>
                <Button
                  sx={{
                    position: "absolute",
                    right: 0,
                    left: 0,
                    top: 0,
                    bottom: 0,
                  }}
                  onClick={() => setPlaying((prev) => !prev)}
                >
                  <img
                    src={`${process.env.CMS_URI}/wp-content/uploads/2023/07/Play-button.png`}
                    alt="thumbnail"
                    style={{
                      width: matches ? "60px" : "100px",
                      height: matches ? "60px" : "100px",
                      marginRight: "0",
                      cursor: "pointer",
                    }}
                  />
                </Button>
              </>
            )}
          </Box>
          <Container maxWidth="lg" sx={{ py: 6 }}>
            <Grid container spacing={4} justifyContent="center">
              <Grid item xs={12} sm={4} md={3}>
                <DropDownField
                  fullWidth
                  id="flavour"
                  value={flavours}
                  size="small"
                  onChange={(event) => setFlavours(event.target.value)}
                  select
                  label="Choose Flavour"
                >
                  <MenuItem value=""></MenuItem>
                  {data?.flavours?.nodes?.map((option: any) => (
                    <MenuItem key={option.id} value={option.slug}>
                      {option.name}
                    </MenuItem>
                  ))}
                </DropDownField>
              </Grid>
              <Grid item xs={12} sm={4} md={3}>
                <DropDownField
                  fullWidth
                  id="Intensity"
                  size="small"
                  select
                  onChange={(event) => setIntensities(event.target.value)}
                  value={intensities}
                  label="Choose Intensity"
                >
                  <MenuItem value=""></MenuItem>
                  {data?.intensities?.nodes?.map((option: any) => (
                    <MenuItem key={option.id} value={option.slug}>
                      {option.name}
                    </MenuItem>
                  ))}
                </DropDownField>
              </Grid>
              <Grid item xs={12} sm={4} md={3}>
                <DropDownField
                  fullWidth
                  id="Portfolio"
                  size="small"
                  onChange={(event) => setPortfolio(event.target.value)}
                  value={portfolios}
                  select
                  label="Choose Portfolio"
                >
                  <MenuItem value=""></MenuItem>
                  {data?.portfolios?.nodes?.map((option: any) => (
                    <MenuItem key={option.id} value={option.slug}>
                      {option.name}
                    </MenuItem>
                  ))}
                </DropDownField>
              </Grid>
            </Grid>
          </Container>
          <Box
            sx={{
              "&:before": {
                content: '""',
                position: "absolute",
                top: 0,
                width: "100%",
                height: "100%",
                zIndex: -1,
                bottom: 0,
                backgroundImage: `url(${imageMap[
                  "Red-Black-Gradient-3-with-pattern"
                ]()})`,
                backgroundRepeat: "no-repeat",
                backgroundSize:
                  filteredPortfolios.length === 1 ? "cover" : "contain",
                backgroundPosition: "bottom right",
                transform: "rotateY(180deg)",
              },
              py: 6,
              position: "relative",
              zIndex: 1,
            }}
          >
            {!isEmpty(filteredPortfolios) &&
              filteredPortfolios.map((web: any) => (
                <WebinarRow
                  key={web.id}
                  title={web.name}
                  slug={web.slug}
                  intensity={intensities}
                  flavour={flavours}
                  onViewAllClick={() => history.push(`/category/${web.slug}`)}
                />
              ))}
          </Box>
          <Box sx={{ bgcolor: "#fff", py: 6 }}>
            <WebinarPostSlider
              title="Suggested For you"
              categoryName="suggested"
              count={5}
              variant="dark"
            />
          </Box>
          <Box
            sx={{
              "&:before": {
                content: '""',
                position: "absolute",
                top: 0,
                width: "100%",
                height: "100%",
                zIndex: -1,
                bottom: 0,
                backgroundImage: `url(${imageMap[
                  "Red-Black-Gradient-3-with-pattern"
                ]()})`,
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
                backgroundPosition: "bottom left",
                transform: "rotateY(0deg) rotateX(180deg)",
              },
              py: 6,
              position: "relative",
              zIndex: 1,
            }}
          >
            <WebinarRow title="Latest" count={3} />
          </Box>
        </>
      )}
    </>
  );
};

export default OnDemand;
